export const MODULES = {
  LAYOUT: 'layout',
  CONTACTS: 'contacts',
  VIEW360: '360view',
  MEETINGS: 'meetings',
  DOCUMENTS: 'documents',
  TEMPLATES: 'templates',
  FILES: 'files',
  SETUP: 'setup',
  SETTINGS: 'settings',
} as const

/** Meetings */
export const MEETINGS_SUBMODULES = {
  FIRST_STEP: 'firstStep',
  SECOND_STEP: 'secondStep',
  THIRD_STEP: 'thirdStep',
  STEPS: 'steps',
  CREATE_EDIT_NOTE_MODAL: 'createEditNoteModal',
  SELECT_GREETING_MODAL: 'selectGreetingModal',
  ADD_EDIT_GREETING_MODAL: 'addEditGreetingModal',
  FILTERS: 'filters',
}

/** Setup */
export const SETUP_SUBMODULES = {
  QUICK_START: 'quickStart',
  ADDITIONAL_RESOURCES: 'additionalResources',
}

/** Contacts */

export const CONTACTS_SUBMODULES = {
  DASHBOARD: 'dashboard',
  DETAILS: 'details',
  EVENTS: 'events',
  NOTES: 'notes',
  TASKS: 'tasks',
  DOCUMENTS: 'documents',
}

/** LAYOUT */
export const LAYOUT_SUBMODULES = {
  HEADER: 'header',
  SIDEBAR: 'sidebar',
}

/** SETTINGS */
export const SETTINGS_SUBMODULES = {
  MENU: 'menu',
  DETAILS: 'details',
  USERS: 'users',
  TAGS: 'tags',
  TOPICS: 'topics',
  INTEGRATIONS: 'integrations',
  PERMISSIONS: 'permissions',
  EMAIL_SETTINGS: 'emailSettings',
  EMAIL_SIGNATURES: 'emailSignatures',
  WET_SIGNATURES: 'wetSignatures',
}

export const VIEW360_SUBMODULES = {
  TABLE: 'table'
}

export const createTourLabel =
  (module: (typeof MODULES)[keyof typeof MODULES], submodule: string) => (element: string, action: string) => {
    return `${module}_${submodule}_${element}_${action}`
  }
