import * as apiService from 'lib/apollo/client'

import DataError, { IsError } from '../../helpers/dataError'
import {
  ConfirmTranscriptionDocument,
  ConfirmTranscriptionMutation,
  ConfirmTranscriptionMutationVariables,
  CreateTranscriptionDocument,
  CreateTranscriptionMutation,
  CreateTranscriptionMutationVariables,
  CreateTranscriptionSummaryDocument,
  CreateTranscriptionSummaryMutation,
  CreateTranscriptionSummaryMutationVariables,
  CreateUploadTranscriptionFileUrlDocument,
  CreateUploadTranscriptionFileUrlMutation,
  CreateUploadTranscriptionFileUrlMutationVariables,
  RetriggerTranscriptionDocument,
  RetriggerTranscriptionMutation,
  RetriggerTranscriptionMutationVariables,
  TranscriptionDocument,
  TranscriptionQuery,
  TranscriptionQueryVariables,
  TranscriptionsByEventDocument,
  TranscriptionsByEventQuery,
  TranscriptionsByEventQueryVariables,
  TriggerTranscriptionStepDocument,
  TriggerTranscriptionStepMutation,
  TriggerTranscriptionStepMutationVariables,
  UpdateTranscriptionDocument,
  UpdateTranscriptionMutation,
  UpdateTranscriptionMutationVariables,
  UploadTranscriptionFileDocument,
  UploadTranscriptionFileMutation,
  UploadTranscriptionFileMutationVariables,
} from '../../types/graphql'

class PulseRecorderService {
  async createTranscription(payload: CreateTranscriptionMutationVariables) {
    const { createTranscription: resp } = await apiService.mutate<
      CreateTranscriptionMutation,
      CreateTranscriptionMutationVariables
    >(CreateTranscriptionDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async updateTranscription(payload: UpdateTranscriptionMutationVariables) {
    const { updateTranscription: resp } = await apiService.mutate<
      UpdateTranscriptionMutation,
      UpdateTranscriptionMutationVariables
    >(UpdateTranscriptionDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async triggerTranscriptionStep(payload: TriggerTranscriptionStepMutationVariables) {
    const { triggerTranscriptionStep: resp } = await apiService.mutate<
      TriggerTranscriptionStepMutation,
      TriggerTranscriptionStepMutationVariables
    >(TriggerTranscriptionStepDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async createTranscriptionSummary(payload: CreateTranscriptionSummaryMutationVariables) {
    const { createTranscriptionSummary: resp } = await apiService.mutate<
      CreateTranscriptionSummaryMutation,
      CreateTranscriptionSummaryMutationVariables
    >(CreateTranscriptionSummaryDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.summary
  }

  async getTranscription(id: number) {
    const { transcription: resp } = await apiService.query<TranscriptionQuery, TranscriptionQueryVariables>(
      TranscriptionDocument,
      {
        id,
      },
      { fetchPolicy: 'no-cache' },
    )

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getTranscriptionsByEventId(eventId: number) {
    const { transcriptionsByEvent: resp } = await apiService.query<
      TranscriptionsByEventQuery,
      TranscriptionsByEventQueryVariables
    >(TranscriptionsByEventDocument, {
      eventId,
    })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.transcriptions
  }

  async uploadTranscriptionFile(file: Blob, key: string) {
    const { uploadTranscriptionFile: resp } = await apiService.mutate<
      UploadTranscriptionFileMutation,
      UploadTranscriptionFileMutationVariables
    >(UploadTranscriptionFileDocument, {
      file,
      key,
    })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async uploadTranscriptionFileFromUrl(key: string) {
    const { createUploadTranscriptionFileUrl: resp } = await apiService.mutate<
      CreateUploadTranscriptionFileUrlMutation,
      CreateUploadTranscriptionFileUrlMutationVariables
    >(CreateUploadTranscriptionFileUrlDocument, {
      key,
    })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.url
  }

  async retriggerTranscription(payload: RetriggerTranscriptionMutationVariables) {
    const { retriggerTranscription: resp } = await apiService.mutate<
      RetriggerTranscriptionMutation,
      RetriggerTranscriptionMutationVariables
    >(RetriggerTranscriptionDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async confirmTranscription(payload: ConfirmTranscriptionMutationVariables) {
    const { confirmTranscription: resp } = await apiService.mutate<
      ConfirmTranscriptionMutation,
      ConfirmTranscriptionMutationVariables
    >(ConfirmTranscriptionDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }
}

const pulseRecorderService = new PulseRecorderService()

export default pulseRecorderService
