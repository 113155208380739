import { Table } from 'antd'
import styled, { createGlobalStyle } from 'styled-components'
import colors, { logoColors } from './colors'
import media from './media'

import { CSSProperties } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './fonts/lato/lato.css'

const styles = {
  sideMenuWidth: 100, // width of far left dark menu when expanded
  sideMenuCollapsedWidth: 60, // width of far left dark menu when collapsed

  leftSiderWidthSm: document.documentElement.clientWidth < 1200 ? 160 : 200,
  leftSiderWidth: document.documentElement.clientWidth < 1400 ? 180 : 240,
  leftSiderWidthLg: document.documentElement.clientWidth < 1200 ? 300 : 420,
  rightSiderWidth: 320,

  headerHeight: 64,
  padding: 16,
  logoColors,
  colors,
  fonts: {
    size: {
      huge: 28,
      large: 16,
      medium: 14,
      normal: 12,
      small: 11,
    },
  },
  icons: {
    size: {
      large: 16,
      medium: 14,
      small: 10,
    },
  },
  media,
}

// @media only screen and (max-width : 1200px)
// @media only screen and (max-width : 992px)
// @media only screen and (max-width : 768px)

export interface FlexRowColProps {
  justify?: CSSProperties['justifyContent']
  align?: CSSProperties['alignContent']
  direction?: 'row' | 'column' | (string & {})
  [prop: string]: any
}

export const FlexRow = styled.div<FlexRowColProps>`
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'center'};
  flex-direction: ${(p) => p.direction || 'row'};
  display: flex;
`

export const FlexCol = styled.div<FlexRowColProps>`
  justify-content: ${(props) => props.justify || 'flex-start'};
  flex-direction: column;
  display: flex;
`

export const FlexSpanCol = styled.span<FlexRowColProps>`
  justify-content: ${(props) => props.justify || 'flex-start'};
  flex-direction: column;
  display: flex;
`

export const Spacer = styled(FlexRow)`
  > *:not(:last-child) {
    margin-right: ${(p) => p.space || 8}px!important;
  }
`

export const VSpacer = styled(FlexCol)`
  > *:not(:last-child) {
    margin-bottom: ${(p) => p.space || 8}px!important;
  }
`

export interface HeaderProps {
  offset?: number
  padTop?: number
  color?: string
  [prop: string]: any
}

export const Header = styled.h3<HeaderProps>`
  width: 100%;
  background: ${styles.colors.darkWhite};
  border-left: 4px solid ${(p) => (p.color && styles.colors[p.color]) || styles.colors.primary};
  margin-bottom: ${(p) => (p.pad ? 30 : 16)}px!important;
  ${(p) => p.offset && `margin-left: -14px;width: calc(100% + 14px)!important;`}
  ${(p) => p.padTop && `margin-top: 30px!important;`}
  color: ${styles.colors.extraLightBlack};
  padding-left: 10px;
`

export const P = styled.p`
  margin-bottom: 30px;
`

export const TallTable = styled(Table)`
  height: 100%;
  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
      .ant-table {
        height: 100%;
        display: flex;
        flex-direction: column;
        .ant-table-content {
          flex: 1;
          .ant-table-scroll {
            display: flex;
            flex-direction: column;
            height: 100%;
            .ant-table-placeholder {
              display: none;
            }
            .ant-table-body {
              flex: 1;
              /* max-height: unset!important; */
            }
          }
        }
      }
    }
  }
`

export default styles

const floatButton = `
[class^='ant-float-btn'],
[class*=' ant-float-btn'] {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
  box-sizing: border-box;
}

[class^='ant-float-btn']::before,
[class*=' ant-float-btn']::before,
[class^='ant-float-btn']::after,
[class*=' ant-float-btn']::after {
  box-sizing: border-box;
}

[class^='ant-float-btn'] [class^='ant-float-btn'],
[class*=' ant-float-btn'] [class^='ant-float-btn'],
[class^='ant-float-btn'] [class*=' ant-float-btn'],
[class*=' ant-float-btn'] [class*=' ant-float-btn'] {
  box-sizing: border-box;
}

[class^='ant-float-btn'] [class^='ant-float-btn']::before,
[class*=' ant-float-btn'] [class^='ant-float-btn']::before,
[class^='ant-float-btn'] [class*=' ant-float-btn']::before,
[class*=' ant-float-btn'] [class*=' ant-float-btn']::before,
[class^='ant-float-btn'] [class^='ant-float-btn']::after,
[class*=' ant-float-btn'] [class^='ant-float-btn']::after,
[class^='ant-float-btn'] [class*=' ant-float-btn']::after,
[class*=' ant-float-btn'] [class*=' ant-float-btn']::after {
  box-sizing: border-box;
}

.ant-float-btn-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  z-index: 99;
  display: block;
  border: none;
  position: fixed;
  width: 40px;
  height: auto;
  box-shadow: none;
  min-height: 40px;
  inset-inline-end: 24px;
  inset-block-end: 48px;
  border-radius: 8px;
}

.ant-float-btn-group .ant-float-btn-group-wrap {
  z-index: -1;
  display: block;
  position: relative;
  margin-bottom: 16px;
}

.ant-float-btn-group.ant-float-btn-group-rtl {
  direction: rtl;
}

.ant-float-btn-group .ant-float-btn {
  position: static;
}

.ant-float-btn-group-circle .ant-float-btn-circle:not(:last-child) {
  margin-bottom: 16px;
}

.ant-float-btn-group-circle .ant-float-btn-circle:not(:last-child) .ant-float-btn-body {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.ant-float-btn-group-square .ant-float-btn-square {
  border-radius: 0;
  padding: 0;
}

.ant-float-btn-group-square .ant-float-btn-square:first-child {
  border-start-start-radius: 8px;
  border-start-end-radius: 8px;
}

.ant-float-btn-group-square .ant-float-btn-square:last-child {
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
}

.ant-float-btn-group-square .ant-float-btn-square:not(:last-child) {
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.ant-float-btn-group-square .ant-float-btn-square .ant-badge .ant-badge-count {
  top: -10px;
  inset-inline-end: -10px;
}

.ant-float-btn-group-square .ant-float-btn-group-wrap {
  display: block;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-float-btn-group-square .ant-float-btn-group-wrap .ant-float-btn-square {
  box-shadow: none;
  margin-top: 0;
  border-radius: 0;
  padding: 4px;
}

.ant-float-btn-group-square .ant-float-btn-group-wrap .ant-float-btn-square:first-child {
  border-start-start-radius: 8px;
  border-start-end-radius: 8px;
}

.ant-float-btn-group-square .ant-float-btn-group-wrap .ant-float-btn-square:last-child {
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
}

.ant-float-btn-group-square .ant-float-btn-group-wrap .ant-float-btn-square:not(:last-child) {
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.ant-float-btn-group-square .ant-float-btn-group-wrap .ant-float-btn-square .ant-float-btn-body {
  width: 32px;
  height: 32px;
}

.ant-float-btn-group-circle-shadow {
  box-shadow: none;
}

.ant-float-btn-group-square-shadow {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-float-btn-group-square-shadow .ant-float-btn-square {
  box-shadow: none;
  padding: 4px;
}

.ant-float-btn-group-square-shadow .ant-float-btn-square .ant-float-btn-body {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

.ant-float-btn {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  border: none;
  position: fixed;
  cursor: pointer;
  z-index: 99;
  display: block;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  inset-inline-end: 24px;
  inset-block-end: 48px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-float-btn-pure {
  position: relative;
  inset: auto;
}

.ant-float-btn:empty {
  display: none;
}

.ant-float-btn .ant-badge {
  width: 100%;
  height: 100%;
}

.ant-float-btn .ant-badge .ant-badge-count {
  transform: translate(0, 0);
  transform-origin: center;
  top: -6px;
  inset-inline-end: -6px;
}

.ant-float-btn .ant-float-btn-body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}

.ant-float-btn .ant-float-btn-body .ant-float-btn-content {
  overflow: hidden;
  text-align: center;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
}

.ant-float-btn .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
  text-align: center;
  margin: auto;
  width: 18px;
  font-size: 18px;
  line-height: 1;
}

.ant-float-btn-rtl {
  direction: rtl;
}

.ant-float-btn-circle {
  height: 40px;
  border-radius: 50%;
}

.ant-float-btn-circle .ant-badge .ant-badge-dot {
  top: 5.857864376269049px;
  inset-inline-end: 5.857864376269049px;
}

.ant-float-btn-circle .ant-float-btn-body {
  border-radius: 50%;
}

.ant-float-btn-square {
  height: auto;
  min-height: 40px;
  border-radius: 8px;
}

.ant-float-btn-square .ant-badge .ant-badge-dot {
  top: 2.3431457505076194px;
  inset-inline-end: 2.3431457505076194px;
}

.ant-float-btn-square .ant-float-btn-body {
  height: auto;
  border-radius: 8px;
}

.ant-float-btn-default {
  background-color: #ffffff;
  transition: background-color 0.2s;
}

.ant-float-btn-default .ant-float-btn-body {
  background-color: #ffffff;
  transition: background-color 0.2s;
}

.ant-float-btn-default .ant-float-btn-body:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.ant-float-btn-default .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
  color: rgba(0, 0, 0, 0.88);
}

.ant-float-btn-default .ant-float-btn-body .ant-float-btn-content .ant-float-btn-description {
  display: flex;
  align-items: center;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 12px;
}

.ant-float-btn-primary {
  background-color: ${styles.colors.primary};
}

.ant-float-btn-primary .ant-float-btn-body {
  background-color: ${styles.colors.primary};
  transition: background-color 0.2s;
}

.ant-float-btn-primary .ant-float-btn-body:hover {
  background-color: ${styles.colors.hover};
}

.ant-float-btn-primary .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
  color: #fff;
}

.ant-float-btn-primary .ant-float-btn-body .ant-float-btn-content .ant-float-btn-description {
  display: flex;
  align-items: center;
  line-height: 16px;
  color: #fff;
  font-size: 12px;
}

.ant-fade-enter,
.ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

@keyframes css-190m0jy-antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  animation-name: css-190m0jy-antFadeIn;
  animation-play-state: running;
}

@keyframes css-190m0jy-antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ant-fade-leave.ant-fade-leave-active {
  animation-name: css-190m0jy-antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}

.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}

.ant-fade-leave {
  animation-timing-function: linear;
}

.ant-float-btn-group-wrap.ant-float-btn-group-wrap-enter,
.ant-float-btn-group-wrap.ant-float-btn-group-wrap-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.ant-float-btn-group-wrap.ant-float-btn-group-wrap-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

@keyframes css-190m0jy-antFloatButtonMoveDownIn {
  0% {
    transform: translate3d(0, 40px, 0);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    transform-origin: 0 0;
    opacity: 1;
  }
}

.ant-float-btn-group-wrap.ant-float-btn-group-wrap-enter.ant-float-btn-group-wrap-enter-active,
.ant-float-btn-group-wrap.ant-float-btn-group-wrap-appear.ant-float-btn-group-wrap-appear-active {
  animation-name: css-190m0jy-antFloatButtonMoveDownIn;
  animation-play-state: running;
}

@keyframes css-190m0jy-antFloatButtonMoveDownOut {
  0% {
    transform: translate3d(0, 0, 0);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 40px, 0);
    transform-origin: 0 0;
    opacity: 0;
  }
}

.ant-float-btn-group-wrap.ant-float-btn-group-wrap-leave.ant-float-btn-group-wrap-leave-active {
  animation-name: css-190m0jy-antFloatButtonMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}

.ant-float-btn-group-wrap.ant-float-btn-group-wrap-enter,
.ant-float-btn-group-wrap.ant-float-btn-group-wrap-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-float-btn-group-wrap.ant-float-btn-group-wrap-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
`

const buttonFocusStyles = `
.ant-btn:focus {
  outline: auto;
}
`

export const GlobalStyle = createGlobalStyle`

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  overflow: hidden;
  color: #2e2728;
  height: 100vh;
  background: linear-gradient(${styles.colors.gray1}, ${styles.colors.gray3} 28%);

  & > div {
    height: 100%;
  }
}

.pulse {
  -webkit-animation: pulseAnimation 1s infinite;
  -moz-animation: pulseAnimation 1s infinite;
  -o-animation: pulseAnimation 1s infinite;
  animation: pulseAnimation 1s infinite;
}
@keyframes pulseAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.hlp-checklist-button {
  border-radius: 2px!important;
  height: 32px!important;
}

.remirror-theme {
  .remirror-editor-wrapper {
    padding-top: 0!important;

    .remirror-editor {
      box-shadow: none!important;

      border: 1px solid ${colors.gray5};
      border-radius: 0!important;
      cursor: text;

      &.inline {
        border: none;
        border-top: 1px solid ${colors.gray5};
        border-bottom: 1px solid ${colors.gray5};
        padding: 2px 8px !important;
      }

      &.padding {
        padding: 20px !important;
      }


      &.no-min-height {
        min-height: unset;
      }
    }
  }
}

.ant-tooltip {
  pointer-events: none;

  &.full {
    max-width: unset;
    .ant-tooltip-inner {
      text-align: center;
    }
  }
}

.p360-color-picker__popup {
  .ant-popover-inner-content {
    padding: 0;
    .twitter-picker {

    }
    .github-picker {
      > div:nth-child(1),
      > div:nth-child(2) {
        display: none;
      }
    }
    .p360-color-picker__picker {
      box-shadow: unset!important;
      border: unset!important;
      width: 160px!important;
    }
  }
}

.p360-ws-menu,
.p360-user-menu,
.p360-shortcut-menu {
  .ant-menu-item-group {
    min-width: 250px;
    .ant-menu-item-group-title {
      background: ${styles.colors.darkWhite};
      text-transform: uppercase;
      margin-bottom: 0px!important;
      font-family: 'proxima-nova';
      font-weight: 600;
      color: rgba(0,0,0,1);
      font-size: 11px;
    }
    .ant-menu-item-group-list {
      max-height: 500px;
      overflow: auto;

      .ant-menu-item {
        &.ws {
          margin-bottom: 0px!important;
        }
      }
    }
  }
  .ant-menu-item {
    padding: 0 16px 0 16px;
    line-height: 30px;
    height: 30px;
    .active {
      color: ${colors.blue};
      font-weight: bold;
    }
    &.add {
      color: ${colors.green};
    }
  }
}

.p360-shortcut-menu .ant-menu-item-group .ant-menu-item-group-title {
  text-transform: none;
  font-size: 13px;
}

.p360-ws-menu, .p360-shortcut-menu {
  z-index: 9999;
  .ant-popover-inner-content {
    padding: 0;
  }
}

.pulse360-alert {
  /* margin-left: 200px; */
  &.ant-message {
    top: unset;
  }
  .ant-message-notice {
    padding-bottom: 0;
  }
  .ant-message-notice-content {
    margin-left: 60px;
    background: rgba(58,71,88,0.9);
    font-family: 'proxima-nova';
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 16px 30px;
    font-size: 15px;
    color: white;

    .ant-message-custom-content {
      display: flex;
    }

    .anticon {
      margin-right: 24px;
      color: ${colors.lime};
    }
  }
}

.p360-custom-ph[data-tooltip]:hover:after {
  opacity: 1;
  visibility: visible;
}
.p360-custom-ph[data-tooltip]:after {
  content: attr(data-tooltip);
  background-color: #000000;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  border-radius: 2px;
  padding: 0px 4px;
  top: -20px;
  left: 90%;
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
.p360-custom-ph[data-tooltip] {
  position: relative;
}

.p360-ph,
.p360-custom-ph {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;

  display: inline-block;

  border-radius: 2px;
  cursor: pointer;
  padding: 0px 3px;

  &.missing {
    color: ${styles.colors.error};
    font-weight: bold;
  }

  &[data-header='add'] {
    box-shadow: rgb(83 188 49 / 100%) 0px 1px 1px, rgb(83 188 49 / 75%) 0px 0px 1px 1px;
  }

  &[data-header='remove'] {
    box-shadow: rgb(246 59 100 / 100%) 0px 1px 1px, rgb(246 59 100 / 75%) 0px 0px 1px 1px;
  }

  &[data-phdisplay='paragraph'], &[data-phdisplay='ollist'], &[data-phdisplay='ullist']  {
    display: table;
  }
}

.p360-custom-ph {
  // box-shadow: rgb(32 160 255 / 100%) 0px 1px 1px, rgb(32 160 255 / 75%) 0px 0px 1px 1px;

  &:not([data-custom="CLIENT"]) {
    box-shadow: rgb(32 160 255 / 100%) 0px 1px 1px, rgb(32 160 255 / 75%) 0px 0px 1px 1px;
  }

  &.riskalyze-value,
  &.riskalyze-score {
    box-shadow: rgb(231 150 63 / 100%) 0px 1px 1px, rgb(231 150 63 / 75%) 0px 0px 1px 1px;
    background-image: url("static/images/riskalyze.png");
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: 0px 1px;
    padding: 0px 0px 0px 17px;
  }
}

.fr-custom-ph-edit-layer {
  .fr-input-line {
    padding: 0!important;

    input + label {
      top: 15px!important;
    }

    input.fr-not-empty + label {
      font-size: 11px;
      top: -5px!important;
    }
  }
}

.p360-picker {
  .ant-calendar-input-wrap {
    display: none;
  }
}

.add-note-modal {
  .ant-modal-content {
    border-radius: 6px;
    .ant-modal-header {
      border-radius: 6px 6px 0 0;
    }
    .ant-modal-body {
      background: ${colors.offWhite};
    }
  }
}

@-webkit-keyframes slideUp {
  from { bottom: -60px }
  to { bottom: 0 }
}

.fr-popup {
  z-index: 2147483635!important;

  .fr-table-colors,
  .fr-color-set {
    padding: 0!important;
    &.fr-selected-set {
      padding: 0!important;
    }
  }
}

.placeHolderAddTopicHeaders,
.placeHolderRemoveTopicHeaders {
  font-size: 18px!important;
  position: relative;
  bottom: 2px;

  &.fr-active {
    > svg > path {
      fill: ${colors.primary}!important;
    }
  }
}

.icon-material-fix {
    /* Remove first layer */
  svg {
    > g:nth-child(1) {
      stroke: none;
      fill: none;
    }
    > path:nth-child(1) {
      stroke: none;
      fill: none;
    }
  }
}

/* Remove when migrating to antd 5 */
/* START */
.ant-card {
  border-radius: 8px;
}

.ant-card .ant-card-head {
  border-radius: 8px 8px 0 0;
}

.ant-card .ant-card-body {
  border-radius: 0 0 8px 8px;
}

.ant-table-wrapper .ant-table {
  border-radius: 8px 8px 0 0;
}

.ant-menu-light.ant-menu-root.ant-menu-inline, .ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline, .ant-menu-light.ant-menu-root.ant-menu-vertical, .ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: 0px solid rgba(5,5,5,.06);
}

.ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  padding-inline: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-inline: 4px;
  margin-block: 4px;
  width: calc(100% - 8px);
}

.ant-menu .ant-menu-item, .ant-menu .ant-menu-submenu, .ant-menu .ant-menu-submenu-title {
  border-radius: 8px;
}

.ant-menu .ant-menu-item {
  padding-left: 24px;
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, .ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: ${styles.colors.selectedBackground};
}

.ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
  content: none;
}

.ant-modal .ant-modal-content {
  border-radius: 8px;
  padding: 20px 24px;
}

.ant-modal .ant-modal-header {
  border-radius: 8px 8px 0 0;
  margin-bottom: 8px;
  border-bottom: 0;
  padding: 0;
}

.ant-modal .ant-modal-body {
  padding: 0;
}

.ant-modal .ant-modal-footer {
  padding: 0;
  border-top: 0;
  border-radius: 0;
  margin-top: 12px;
}

.ant-dropdown .ant-dropdown-menu, .ant-dropdown-menu-submenu .ant-dropdown-menu {
  padding: 4px;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0,0,0,.08), 0 3px 6px -4px rgba(0,0,0,.12), 0 9px 28px 8px rgba(0,0,0,.05);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item, .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0,0,0,.88);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: all .2s;
  border-radius: 4px;
}

${floatButton}

${buttonFocusStyles}



/* END */

.ant-notification {
  z-index: 2147483648;
}

/* Table filters */

.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}

.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid rgba(0,0,0,.06);
}

`
